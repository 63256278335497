export const fetchMyProfile = async (setIsLoadingCallback: any, setMyProfileCallback: any) => {
    
    const accessToken = localStorage.getItem('token')
    // console.log(accessToken)
    fetch('https://dev.fyndr.tech/api/v1/user', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },

    })
        .then((response) => response.json())
        .then((data) => {
            // console.log(data)
            setMyProfileCallback(data.profile)
        }
        )
        .catch((error) => {
            console.error('Error:', error);
            
        }
        );
};