import React, { useEffect } from "react"
import { SignIn } from "../../services/Authentication";
import { CircularProgress } from '@mui/material';
import Button from '@mui/joy/Button';
import { InputBase } from "@mui/material";
// import IconButton from '@mui/joy/IconButton';
import { GoogleLogin } from '@react-oauth/google';
import { socialAuth, checkToken } from "../../services/Authentication";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";


export default function Auth() {
    const [isLoading, setIsLoading] = React.useState(false)
    const [googleLoading, setGoogleLoading] = React.useState(false)
    const [linkedinLoading, setLinkedinLoading] = React.useState(false)

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [userInfo, setUserInfo] = React.useState()

    const navigate = useNavigate();


    useEffect(() => {
        checkToken(navigate)
        // console.log("Use effect")
    }, []);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        SignIn(email, password, setIsLoading, navigate);
    };

    const responseMessage = async (response: any) => {
        console.log(response);
        const token = response.credential
        const userObject: any = jwt_decode(token);
        console.log(userObject)

        try {
            setEmail(userObject.email)
            socialAuth(userObject.email, "google", setGoogleLoading, setLinkedinLoading, navigate)

        } catch (error) {
            
        }

    };
    const errorMessage = (error: any) => {
        console.log(error);
    };



    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                    <p className="mt-10 text-center text-2xl font-inter font-light leading-9 tracking-tight text-label">
                        Manage your events
                    </p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                            </div>
                            <div className="mt-2">
                                <InputBase
                                    value={email}
                                    size="medium"
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 font-inter text-label shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-label focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-10"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>

                            </div>
                            <div className="mt-2">
                                <InputBase
                                    value={password}
                                    size="medium"
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 font-inter text-label shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-label focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 px-10"
                                />
                            </div>
                        </div>

                        <div>
                            {/* <button
                                type="submit"
                                className="flex w-full justify-center text-justify rounded-md bg-primary px-3 py-1.5 text-sm font-inter leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary h-max"
                            >

                                Sign in

                            </button> */}
                            <Button
                                sx={{ backgroundColor: "#ff7e36", ":hover": { backgroundColor: "#ff7e36", opacity: 0.7 } }}
                                type="submit"
                                size="lg"
                                className="flex w-full justify-center text-justify rounded-md bg-primary px-3 py-1.5 text-sm font-inter leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary h-max"
                            >
                                {isLoading ? (
                                    <CircularProgress sx={{color: '#fff'}} color="success" className="text-white" variant="indeterminate" thickness={2} size={28} />
                                   
                                ) : (
                                    
                                    "Sign in"
                                )}
                            </Button>

                            <div className="mt-5">
                                <GoogleLogin onSuccess={credentialResponse => {
                                    responseMessage(credentialResponse);
                                }} />
                            </div>
                        </div>
                    </form>

                    <p className="mt-10 text-center font-inter text-sm text-label">

                        <a href="#" className="font-semibold leading-6 text-primary text-sm hover:text-primary">
                            Register on the mobile app to start creating events
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
}
