import React from 'react';

import Sidebar from '../layouts/Sidebar';

const Dashboard: React.FC = () => {

  return (
    
      <Sidebar/>
    
  );
};

export default Dashboard;
