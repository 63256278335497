import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface StatisticsProps {
  totalMembers: number;
  connectionsMade: number;
  membersActive: number;
  connectionsSuggested: number;
}

const EventStatisticsChart = ({ statistics }: { statistics: StatisticsProps }) => {
  const data = [
    { name: 'Total Members', value: statistics.totalMembers },
    { name: 'Made', value: statistics.connectionsMade },
    { name: 'Members Active', value: statistics.membersActive },
    { name: 'Suggested', value: statistics.connectionsSuggested },
  ];

  return (
    <div className="chart-container mx-auto font-inter font-thin text-xs text-opacity-20">
    <ResponsiveContainer aspect={1.1}>
      <LineChart width={700} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#ff7e36" activeDot={{ r: 8 }} />
      </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EventStatisticsChart;
