import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Avatar } from '@mui/material';
import { ArrowOutwardOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/joy/CircularProgress';


import { HomeIcon } from '@heroicons/react/24/outline';

import { FyndrLogo } from '../atoms/FyndrLogo';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/joy/Button';

import { useNavigate } from "react-router-dom";

import { Text } from '@nextui-org/react';

import { fetchMyProfile } from '../../services/MyProfile';
import { getModifiedHostedEvents } from '../../services/Events';
import { loadChat } from '../../services/Messages';



import { Search as SearchIcon } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import MemberList from './MemberList';
import EventTitle from './EventArea';
import Insights from './Insights';

import { signOut } from '../../services/Authentication';


import EventsContent from './EventsContent';
import EventArea from './EventArea';
import HomeContent from './HomeContent';

import { Backdrop } from '@mui/material';

import { IoSearchOutline, IoCloseOutline, IoMoonOutline } from 'react-icons/io5'
import { IoIosMoon } from 'react-icons/io'
import { SlEvent } from 'react-icons/sl'
import { HiOutlineLightBulb, HiLightBulb } from 'react-icons/hi'
import { PiSunLight } from 'react-icons/pi'



import axios from 'axios';


const drawerWidth = 300;

interface Myprofile {
    name: string;
    lname: string;
    image: string;
    id: string;
    email: string;
    lookingfor: string;
    offer: string;
    about: string;
}

interface Event {
    eventName: string;
    eventHost: any;
    eventId: string;
    waitList: any;
    eventLocation: any;
    eventDate: any;
    eventTime: any;
    eventImage: any;
    eventSponsor: any;
    eventIdentifier: any;
    chats: any;
    eventStatus: any;
}

interface Chats {
    image: string;
    name: string;
    lname: string;
    email: string;
    type: string;
    id: any;
}

interface SearchResult {
    //   id: number;
    //   title: string;
    name: string;
    lname: string;
    image: string;
    id: string;
    email: string;
    offer: string;

}


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Sidebar() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const [isLoading, setIsLoading] = React.useState(true)
    const [dummyLoading, setDummyLoading] = React.useState(false)
    // const [myProfile, setMyProfile] = React.useState({})
    const [myProfile, setMyProfile] = React.useState<Myprofile | undefined>(undefined);
    // const [myProfile, setMyProfile] = React.useState<Myprofile>({});
    // const [eventData, setEventData] = React.useState([])
    const [eventData, setEventData] = React.useState<Event[]>([]);
    const [newChats, setNewChats] = React.useState<Chats[]>([]);
    const [newChatsM, setNewChatsM] = React.useState<Chats[]>([]);

    const [searchOpen, setSearchOpen] = React.useState(false)

    const [selectedTab, setSelectedTab] = React.useState('home');

    const [selectedEventIndex, setSelectedEventIndex] = React.useState<number | null>(null);

    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);
    const [darkToggle, setDarkToggle] = React.useState(false)

    

    

    const handleEventClick = (index: number, tab: any) => {
        setSelectedTab(tab);
        setSelectedEventIndex(index);

    };

    const handleHomeClick = () => {
        setSelectedTab("home")
        setSelectedEventIndex(null)
    }

    const handleSidebarItemClick = (tab: any) => {
        setSelectedTab(tab);
    };

    const handleInputChange = (value: string) => {
        console.log("searching", value)
        setSearchTerm(value);
        // performSearch(value);
        fetchSearchResults(value);
    };

    // Fetch search results
    const fetchSearchResults = async (searchQuery: any) => {
        try {
            const url = `https://fyndr-api-9sff6.ondigitalocean.app/api/v1/users/search?query=${searchQuery}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log(responseData);

            //@ts-expect-error
            const filteredData = responseData.users.filter(item => item.email !== myProfile?.email);

            const results: SearchResult[] = filteredData;

            setSearchResults(results);
        } catch (error) {
            console.log('Error fetching search results:', error);
        }
    };


    const handleSearchClose = () => {
        setSearchOpen(false)
    };

    const handleSearchOpen = () => {
        setSearchOpen(true)
    };

    let content;
    switch (selectedTab) {
        case 'home':
            content = <HomeContent eventData={eventData} myEmail={myProfile?.email} setIsLoading={setIsLoading} setEventData={setEventData} />;
            break;
        case 'events':
            content = <EventArea eventData={eventData} currentIndex={selectedEventIndex} myEmail={myProfile?.email} setIsLoading={setIsLoading} setEventData={setEventData}  />;
            break;
        // Add other cases for additional tabs
        default:
            content = null;
            break;
    }

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSignOut = () => {
        signOut(setIsLoading, navigate);
    };

    

    React.useEffect(() => {
        console.log("fetch profile")
        fetchMyProfile(setDummyLoading, setMyProfile)
    }, []);

    React.useEffect(() => {
        if (myProfile) {
            console.log("getting events");
            getModifiedHostedEvents(myProfile.email, setEventData, setIsLoading);
        }
    }, [myProfile]);



    return (
        <div
            className={`flex-col ${darkToggle && 'dark'
                }`}
        >
            <div className='flex bg-inherit dark:bg-black'>
                <CssBaseline />
                <AppBar position="fixed" open={open} color='inherit' className='h-24 flex justify-center border-b border-gray-200 dark:border-gray-800' elevation={0} sx={{backgroundColor: darkToggle ? '#000' : 'inherit'}}>
                    <Toolbar className="grid items-center grid-cols-1 md:grid-cols-2 gap-4 bg-white dark:bg-black">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className="flex items-center w-full bg-white dark:bg-black">
                            <IconButton
                                color="inherit"
                                edge="start"
                                aria-label="open drawer">
                                <SearchIcon className='text-label' />
                            </IconButton>
                            {/* <InputBase
                            placeholder="Type to search..."
                            className="ml-2 bg-white px-2 py-1 squared w-full font-inter font-light text-label text-sm"
                        /> */}
                            <p onClick={handleSearchOpen} className="ml-2 bg-white dark:bg-black px-2 py-1 squared w-full font-inter font-light text-label text-sm text-left cursor-pointer">Click to search</p>

                        </div>

                        <div className='bg-transparent'>
                            {!darkToggle ? (
                                <IoMoonOutline onClick={() => setDarkToggle(!darkToggle)} className='text-label text-3xl' />
                            ) : (
                                <PiSunLight onClick={() => setDarkToggle(!darkToggle)} className='text-label text-3xl' />
                            )}
                        </div>

                        <div className="hidden md:flex items-center w-1/4 mx-10 bg-transparent">
                            <Avatar src={myProfile?.image} className="mx-2 my-7" />
                            <div className='flex flex-col'>
                                <p className='text-center font-inter font-medium text-sm text-secondary dark:text-white'>{`${myProfile?.name}  ${myProfile?.lname}`}</p>
                                <p className='text-left font-inter font-medium text-xs text-label'>{`${myProfile?.offer}`}</p>
                            </div>

                        </div>




                    </Toolbar>
                </AppBar>

                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            borderColor: darkToggle ? '#1f2937' : 'inherit',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}>
                    <DrawerHeader className='dark:bg-black'>
                        <IconButton className='text-label' onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon className='text-label' /> : <ChevronRightIcon className='text-label' />}
                        </IconButton>
                    </DrawerHeader>

                    <div className='px-12 text-center dark:bg-black'>
                        <FyndrLogo />
                    </div>
                    <div className='flex flex-col justify-between bg-white h-full dark:bg-black border-0'>
                        <List>
                            <ListItem >
                                <Button sx={{ ":hover": { color: "#ff7e36", backgroundColor: "transparent" }, backgroundColor: "transparent", color: "#637381" }} onClick={handleHomeClick}>
                                    <ListItemIcon className='px-3'>
                                        <HomeIcon className='w-5 h-5 text-label' />
                                    </ListItemIcon>
                                    {/* <p className='font-inter font-medium text-md px-0'>Home</p> */}
                                    <p className={`font-inter font-medium text-md px-0 ${selectedTab === 'home' ? 'text-primary' : 'text-label'}`}>
                                        Home
                                    </p>
                                </Button>
                            </ListItem>

                            <ListItem>
                                <Button sx={{ ":hover": { color: "#ff7e36", backgroundColor: "transparent" }, backgroundColor: "transparent", color: "#637381" }}>
                                    <Accordion
                                        elevation={0}
                                        disableGutters={true}
                                        className='w-full dark:bg-black'>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon className='text-label' />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >

                                            <div className='flex flex-row bg-transparent dark:bg-black items-center'>
                                                <SlEvent className='text-lg text-label' />
                                                <p className='font-inter font-medium text-md text-label px-6'>Events</p>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {eventData?.map((data, index) => (
                                                <ListItem className="">
                                                    <Button sx={{ ":hover": { color: "#ff7e36", backgroundColor: "transparent" }, color: "#637381", backgroundColor: "transparent" }}
                                                        onClick={() => handleEventClick(index, "events")}>
                                                        <p className={`font-inter font-medium text-md text-left px-3 leading-6 ${selectedEventIndex === index ? "text-primary" : ""}`}>{data.eventName}</p>
                                                    </Button>
                                                </ListItem>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Button>
                            </ListItem>
                        </List>

                        <List>
                            <Divider className='dark:bg-gray-800' />
                            {['Logout'].map((text, index) => (
                                <ListItem key={text}>
                                    <Button onClick={handleSignOut} sx={{ ":hover": { color: "#ff7e36", backgroundColor: "transparent" }, color: "#637381", backgroundColor: "transparent" }}>
                                        <ListItemIcon>
                                            <ArrowOutwardOutlined className='w-5 h-5 text-label' />
                                        </ListItemIcon>
                                        <p className='font-inter font-medium text-md px-0 '>{text}</p>
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Drawer>
                <Main className='dark:bg-black' open={open}>
                    <DrawerHeader />
                    {isLoading ? (
                        <div className='h-screen flex justify-center items-center dark:bg-black'>
                            <CircularProgress className="text-primary" variant="solid" thickness={2} />
                        </div>
                    ) : (
                        <div className='dark:bg-black'>
                            {content}
                        </div>
                    )}

                   

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={searchOpen}
                    // onClick={handleClose}
                    >
                        <div className='h-1/2 bg-white dark:border border-gray-800 dark:bg-black w-1/2'>
                            <div className='bg-transparent flex justify-end pt-8 pr-8'>
                                <IoCloseOutline onClick={handleSearchClose} className='text-label text-2xl hover:text-primary' />
                            </div>
                            <div className="w-full bg-transparent mt-0">
                                <div className='ml-4 bg-transparent flex flex-row items-center mb-3'>
                                    <IoSearchOutline className='text-label text-2xl' />
                                    <InputBase
                                        value={searchTerm}
                                        placeholder="Type to search..."
                                        onChange={(event) => handleInputChange(event.target.value)}
                                        className="w-1/2 dark:bg-black dark:text-label bg-white px-2 py-1 squared font-inter font-extrabold text-label text-sm ml-3"
                                    />
                                </div>
                                <Divider className='dark:bg-gray-800' />

                                <div className="overflow-y-auto h-80 w-full bg-transparent">
                                    {searchResults?.map((result, index) => (
                                        <div className='items-start'>
                                            <Button
                                                sx={{
                                                    ":hover": { color: "#ff7e36", backgroundColor: "transparent" },
                                                    color: "#637381",
                                                    backgroundColor: "transparent",
                                                    width: "100%",
                                                    justifyContent: "flex-start",
                                                    padding: "0",
                                                    border: "none",
                                                    textAlign: "left",
                                                }}
                                                onClick={() => handleEventClick(index, "events")}
                                            >
                                                <div className='flex flex-row items-center justify-between bg-transparent w-full p-4'>
                                                    <div className='flex flex-row items-center'>
                                                        <Avatar src={result.image} className="mx-2 my-2" />
                                                        <text className='font-inter font-light text-md text-left px-3 leading-6' >{result.name + " " + result.lname}</text>
                                                    </div>
                                                    <p>UI designing</p>
                                                </div>
                                            </Button>
                                            <Divider />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>


                    </Backdrop>

                </Main>
            </div>
        </div>
    );
}
