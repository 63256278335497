import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { opacity } from '@cloudinary/url-gen/actions/adjust';

// import { Table, TableHeader, TableCell, TableBody } from 'react-pdf';

const EventSummaryPDF = ({ data }: any) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
       
          <Text style={styles.title}>fyndr</Text>
          <Text style={styles.eventName}>{data.eventName} </Text>
          <Text style={styles.eventAddress}>{data.eventLocation.address} </Text>

          <Text>Participants</Text>
          {data.waitList.map((user: any, index: number) => (
            <View style={{flexDirection: "row", backgroundColor: index % 2 === 0 ? "#ccc" : "#fff"}}>
              <Text style={{...styles.eventTable}}>{user.profile.name}</Text>
              <Text style={{...styles.eventTable, marginLeft: 10}}>{user.profile.email}</Text>
              <Text style={{...styles.eventTable, marginLeft: 10}}>{user.profile.offer}</Text>
              <Text style={{...styles.eventTable, marginLeft: 10}}>{user.profile.lookingFor}</Text>
            </View>

          ))}
        
        </View>



       
        
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    color: '#ff7e36',
    fontSize: '20px',
    fontWeight: 500,

  },
  eventName: {
    color: '#ccc',
    fontSize: '15px'

  },
  eventTable: {
    color: '#000',
    fontSize: '8px',
    opacity: 0.8

  },
  eventAddress: {
    color: '#ccc',
    fontSize: '8px'
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    backgroundColor: '#eeeeee',
    padding: 5,
  },
  columnHeader: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    padding: 5,
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
  },
  image: {
    width: 50,
    height: 50,
  },
});

export default EventSummaryPDF;
