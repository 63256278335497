import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import axios from 'axios';
import { Avatar } from '@mui/material';
import { Skeleton } from '@mui/material';
import { Checkbox } from '@mui/material';
import { IoMdRemoveCircleOutline, IoIosPower, IoIosAdd, IoIosRemove } from 'react-icons/io'
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import { Backdrop } from '@mui/material';
import { IoSearchOutline, IoCloseOutline, IoMoonOutline } from 'react-icons/io5'
import { InputBase } from '@mui/material';
import { getModifiedHostedEvents } from '../../services/Events';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Sponsor {
    profile: {
        name: string;
        lname: string;
        image: string;
        offer: string;
        email: string;
    };
    // Add more properties as needed
}

interface Event {
    eventName: string;
    eventHost: any;
    eventId: string;
    waitList: any;
    eventLocation: any;
    eventDate: any;
    eventTime: any;
    eventImage: any;
    eventSponsor: any;
    eventIdentifier: any;
}

interface HomeProps {
    eventData: Event[];
    myEmail: string | undefined;
    setIsLoading: any;
    setEventData: any;
}

interface SearchResult {
    //   id: number;
    //   title: string;
    name: string;
    lname: string;
    image: string;
    id: string;
    email: string;
    offer: string;

}

interface Myprofile {
    name: string;
    lname: string;
    image: string;
    id: string;
    email: string;
    lookingfor: string;
    offer: string;
    about: string;
}

const HomeContent: React.FC<HomeProps> = ({ eventData, myEmail, setIsLoading, setEventData }) => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [addOpen, setAddOpen] = React.useState(false)


    const [selectedEventIndex, setSelectedEventIndex] = React.useState(0);
    const [selectedSponsorIndex, setSelectedSponsorIndex] = React.useState(0);
    // const [sponsorData, setSponsorData] = React.useState<any[]>([]);
    const [sponsorsToRemove, setSponsorsToRemove] = React.useState<string[]>([]);
    const [sponsorData, setSponsorData] = React.useState<Sponsor[]>([]);
    const [sponsorLoading, setSponsorLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);



    const handleOpenDialog = (index: any) => {
        setOpenDialog(true);
        setSelectedSponsorIndex(index)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const addSponsorsToRemove = (email: string) => {
        setSponsorsToRemove((prevSponsors: string[]) => [...prevSponsors, email]);
    };

    const removeSponsorToRemove = (email: string) => {
        setSponsorsToRemove((prevSponsors: string[]) =>
            prevSponsors.filter(existingEmail => existingEmail !== email)
        );
    };

    const toggleSponsorToRemove = (email: string) => {
        console.log(email)
        if (sponsorsToRemove.includes(email)) {
            setSponsorsToRemove(prevSponsors =>
                prevSponsors.filter(existingEmail => existingEmail !== email)

            );
        } else {
            setSponsorsToRemove(prevSponsors => [...prevSponsors, email]);
        }
    };

    const handleIndex = (index: any) => {
        setSelectedSponsorIndex(0)
        setSelectedEventIndex(index)
        retrieveSponsorData(index)

    };

    const handleAddClose = () => {
        setAddOpen(false)
    };

    const handleAddOpen = () => {
        setAddOpen(true)
    };

    const handleInputChange = (value: string) => {
        console.log("searching", value)
        setSearchTerm(value);
        // performSearch(value);
        fetchSearchResults(value);
    };

    const handleAddClick = (index: any) => {
        // handleAddOpen()
        const newSponsorEmail = [searchResults[index].email]
        addSponsorToEvent(newSponsorEmail)

    };

    // Fetch search results
    const fetchSearchResults = async (searchQuery: any) => {
        try {
            const url = `https://fyndr-api-9sff6.ondigitalocean.app/api/v1/users/search?query=${searchQuery}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log(responseData);

            //@ts-expect-error
            const filteredData = responseData.users.filter(item => item.email !== myEmail);

            const results: SearchResult[] = filteredData;

            setSearchResults(results);
        } catch (error) {
            console.log('Error fetching search results:', error);
        }
    };

    const retrieveSponsorData = async (index: any) => {
        console.log("retrieving sponsor data...")
        const sponsors = eventData[index].eventSponsor
        const responses: any[] = [];

        for (const sponsor of sponsors) {
            try {
                const response = await axios.get(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/user_with_email?user_email=${sponsor}`);
                responses.push(response.data); // Assuming your API returns data in the format {profile: {name: "...", image: "..."}}
                //   console.log(response.data)
            } catch (error) {
                console.error(`Error fetching sponsor data for ${sponsor}: ${error}`);
            }
        }
        console.log(responses)

        setSponsorData(responses)
    }

    const handleRemoveSponsor = (sponsor: any) => {
        // handleCloseDialog()
        removeSponsorsFromEvent(sponsor)
    }

    const removeSponsorsFromEvent = async (sponsorsToRemove: any) => {
        console.log(sponsorsToRemove)
        setSponsorLoading(true)
        const event_id = eventData[selectedEventIndex].eventIdentifier
        try {
            const response = await fetch(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/remove_sponsor_from_event/${event_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sponsorsToRemove }),
            });

            if (response.ok) {
                console.log(response)
                setSponsorLoading(false)
                handleCloseDialog()
                // retrieveSponsorData(selectedEventIndex);
                getModifiedHostedEvents(myEmail, setEventData, setIsLoading);
            }
        } catch (error) {
            console.error('Error removing sponsors:', error);
            setSponsorLoading(false)
            handleCloseDialog()
        }
    };

    const addSponsorToEvent = async (newSponsorEmail: any) => {
        setSponsorLoading(true)
        const event_id = eventData[selectedEventIndex].eventIdentifier
        try {
            const response = await fetch(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/add_sponsor_to_event/${event_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newSponsors: newSponsorEmail }),
            });

            if (response.ok) {
                getModifiedHostedEvents(myEmail, setEventData, setIsLoading);
                // Update the event data or re-fetch it
                // Update any state variables as needed
            }
        } catch (error) {
            console.error('Error adding sponsor:', error);
        }
    };

    React.useEffect(() => {
        if (eventData.length > 0) {
            retrieveSponsorData(selectedEventIndex);
        }
    }, []);



    return (
        <div>
            {/* Top Element */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={addOpen}
            // onClick={handleClose}
            >
                <div className='h-1/2 bg-white dark:border border-gray-800 dark:bg-black w-1/2'>
                    <div className='bg-transparent flex justify-end pt-8 pr-8'>
                        <IoCloseOutline onClick={handleAddClose} className='text-label text-2xl hover:text-primary' />
                    </div>
                    <div className="w-full bg-transparent mt-0">
                        <div className='ml-4 bg-transparent flex flex-row items-center mb-3'>
                            <IoSearchOutline className='text-label text-2xl' />
                            <InputBase
                                value={searchTerm}
                                placeholder="Type to search..."
                                onChange={(event) => handleInputChange(event.target.value)}
                                className="w-1/2 dark:bg-black dark:text-label bg-white px-2 py-1 squared font-inter font-extrabold text-label text-sm ml-3"
                            />
                        </div>
                        <Divider className='dark:bg-gray-800' />

                        <div className="overflow-y-auto h-80 w-full bg-transparent">
                            {searchResults?.map((result, index) => (
                                <div className='items-start'>
                                    <Button
                                        sx={{
                                            ":hover": { color: "#ff7e36", backgroundColor: "transparent" },
                                            color: "#637381",
                                            backgroundColor: "transparent",
                                            width: "100%",
                                            justifyContent: "flex-start",
                                            padding: "0",
                                            border: "none",
                                            textAlign: "left",
                                        }}
                                        onClick={() => handleAddClick(index)}
                                    >
                                        <div className='flex flex-row items-center justify-between bg-transparent w-full p-4'>
                                            <div className='flex flex-row items-center'>
                                                <Avatar src={result.image} className="mx-2 my-2" />
                                                <text className='font-inter font-light text-md text-left px-3 leading-6' >{result.name + " " + result.lname}</text>
                                            </div>
                                            <p>UI designing</p>
                                        </div>
                                    </Button>
                                    <Divider />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Backdrop>
            {sponsorData.length > 0 && (
                <Dialog
                    open={openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseDialog}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>
                        <p className='font-sans font-black text-xl'>Confirm action</p>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <p className='font-inter text-sm'>
                                {selectedSponsorIndex >= 0 ?
                                    `Remove ${sponsorData[selectedSponsorIndex].profile.name} ${sponsorData[selectedSponsorIndex].profile.lname} from the list of sponsors?` :
                                    'Invalid sponsor index'
                                }
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>No, thanks</Button>
                        {!sponsorLoading ? (
                            <Button onClick={() => handleRemoveSponsor([sponsorData[selectedSponsorIndex].profile.email])}>Confirm</Button>
                        ) : (
                            <CircularProgress className="text-primary text-md" variant="solid" thickness={2} />
                        )}
                    </DialogActions>
                </Dialog>
            )}

            <div className="p-8 pl-0 bg-transparent">
                <p className='font-inter font-black text-2xl text-left text-opacity-100 dark:text-opacity-100 dark:text-primary text-primary'>Your Dashboard</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                <div className="p-8 border dark:border-gray-700 border-inherit rounded-lg flex flex-col h-96 bg-transparent overflow-x-auto">
                    <p className='text-left font-inter font-black text-label text-opacity-100 text-lg'> My Events</p>

                    {eventData.length > 0 ? (
                        eventData.map((data, index) => (
                            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-0">
                                <div
                                    className='bg-transparent flex flex-row dark:text-label text-secondary hover:text-primary overflow-x-auto'
                                    key={index}
                                    onClick={() => handleIndex(index)}
                                >
                                    {/* <img src={data.eventImage} className="w-1/4 h-3/4 rounded-xl mr-3" /> */}
                                    <Box className='rounded-xl mr-3'
                                        component="img"
                                        sx={{
                                            height: 'auto',
                                            width: 150,
                                            maxHeight: { xs: 233, md: 167 },
                                            maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="The house from the offer."
                                        src={data.eventImage}
                                    />
                                    <div className='flex items-center bg-transparent h-3/4'>
                                        <div className='flex flex-col bg-transparent'>
                                            <p style={{ cursor: 'pointer' }} className={`font-inter text-lg ${selectedEventIndex === index ? "text-primary" : ""}`}>{data.eventName}</p>
                                            <p style={{ cursor: 'pointer' }} className='font-inter text-secondary text-opacity-50 dark:text-label text-sm text-left'>{data.waitList.length} members</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='flex justify-center items-center'>
                                    <IoMdRemoveCircleOutline className='hover:text-primary' />
                                </div> */}
                            </div>
                        ))
                    ) : (
                        <div className='flex items-center justify-center h-96'>
                            <p className='font-inter text-label text-md'>No Event Data Available</p>
                        </div>
                    )}

                </div>

                <div className="p-8 border dark:border-gray-700 border-inherit rounded-lg bg-transparent flex flex-col h-96">
                    <p className='text-left font-inter font-black text-label text-opacity-100 text-lg'> Sponsors</p>
                    {sponsorData.length > 0 && (
                        //    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-0">
                        //         {/* Render sponsorData here */}
                        //         {sponsorData.map((sponsor, index) => (
                        //            <div className='bg-transparent flex flex-row text-secondary hover:text-primary'
                        //             key={index}
                        //             onClick={() => handleIndex(index)}>
                        //                     {/* <img
                        //                         src={sponsor.profile.image}
                        //                         alt={sponsor.profile.name}
                        //                         className="w-12 h-12 rounded-full"
                        //                     /> */}
                        //                     <Avatar src={sponsor.profile.image} className="w-12 h-12 rounded-full">
                        //                         <Skeleton variant="circular" width={12} height={12} />
                        //                     </Avatar>
                        //                 <div className='flex flex-col gap-0'>
                        //                         <h3 className='font-inter text-left text-secondary ml-3'>{sponsor.profile.name + " " + sponsor.profile.lname}</h3>
                        //                         <p className='font-inter text-label text-xs ml-3 text-left'>{sponsor.profile.email}</p>

                        //                 </div>
                        //             </div>
                        //         ))}
                        //     </div>

                        <div className="overflow-x-auto bg-transparent w-full flex h-full pl-0 pt-5">
                            <table className="w-full bg-transparent justify-start">
                                <tbody className='h-1/2 bg-transparent'>
                                    {sponsorData.map((sponsor, index) => (
                                        <tr
                                            key={index}
                                            className="cursor-pointer flex"
                                        // onClick={() => handleIndex(index)}
                                        >
                                            {/* Avatar */}
                                            <td className="bg-tranparent px-4 py-4">
                                                <Checkbox
                                                    icon={<MdCheckBoxOutlineBlank className='text-label' />}
                                                    checkedIcon={<MdCheckBox className='text-label' />}
                                                    className='text-label'
                                                    checked={sponsorsToRemove.includes(sponsor.profile.email)}
                                                    onChange={() => toggleSponsorToRemove(sponsor.profile.email)}
                                                />
                                            </td>
                                            <td className="px-4 py-4 w-full">
                                                <div className='flex flex-row text-secondary hover:text-primary'>
                                                    <Avatar src={sponsor.profile.image} className="w-10 h-10 rounded-full">
                                                        <Skeleton variant="circular" width={10} height={10} />
                                                    </Avatar>
                                                    <div className='flex flex-col'>
                                                        <p className="font-inter text-label ml-2 text-left">{sponsor.profile.name + " " + sponsor.profile.lname}</p>
                                                        <p className="text-label font-inter font-thin text-left ml-2 text-xs">{sponsor.profile.offer}</p>
                                                    </div>

                                                </div>

                                            </td>
                                            {/* Ban Button */}
                                            <td className="px-4 py-4">
                                                <IoIosRemove onClick={() => handleOpenDialog(index)} className='hover:text-primary text-label' />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    )}

                    {sponsorData.length > 0 && (
                        <div className='flex flex-row gap-4 h-1/4 items-end'>

                            <IoIosAdd onClick={() => setAddOpen(true)} className='text-label text-2xl hover:text-primary'></IoIosAdd>
                            <IoIosRemove onClick={() => removeSponsorsFromEvent(sponsorsToRemove)} className='text-label text-2xl hover:text-primary'></IoIosRemove>
                        </div>
                    )}


                </div>

                <div className="p-8 border dark:border-gray-700 border-inherit rounded-lg flex flex-col  max-h-[70vh] min-h-[50vh] bg-transparent">
                    <p className='text-left font-inter font-black text-label text-opacity-100 text-lg'> Data Requests</p>
                    <div className='flex items-center justify-center h-full w-full'>
                        <p className='font-inter text-label text-md'>No requests</p>
                    </div>
                </div>

                <div className="p-8 border dark:border-gray-700 border-inherit rounded-lg flex flex-col  max-h-[70vh] min-h-[50vh] bg-transparent">
                    <p className='text-left font-inter font-black text-label text-opacity-100 text-lg'>Event charts</p>
                </div>

            </div>

        </div>



    );
}

export default HomeContent