import Divider from '@mui/material/Divider';
import EventStatisticsChart from './EventStatisticsChart';
import React from 'react';
import { Message } from '@mui/icons-material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { RiMessage3Fill } from 'react-icons/ri'
import { AiOutlineMail } from 'react-icons/ai'
import { Checkbox } from '@mui/material';
import { Avatar } from '@mui/material';
import { IoMdRemoveCircleOutline, IoIosPower } from 'react-icons/io'
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import { PiDotOutlineFill } from 'react-icons/pi'
import { Skeleton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getModifiedHostedEvents } from '../../services/Events';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import EventSummaryPDF from './EventSummaryPDF';
import { PDFViewer, PDFDownloadLink, BlobProvider, Link } from '@react-pdf/renderer';
import axios from 'axios';
import { Cloudinary } from '@cloudinary/url-gen';



interface Event {
  eventName: string;
  eventHost: any;
  eventId: string;
  waitList: any;
  eventLocation: any;
  eventDate: any;
  eventTime: any;
  eventIdentifier: any;
  chats: any;
  eventStatus: any;
}

interface Chats {
  image: string;
  name: string;
  lname: string;
  email: string;
  type: string;
  id: any;
}

interface EventProps {
  eventData: Event[];
  currentIndex: any;
  myEmail: string | undefined;
  setIsLoading: any;
  setEventData: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const EventArea: React.FC<EventProps> = ({ eventData, currentIndex, myEmail, setIsLoading, setEventData }) => {

  const [selectedEventIndex, setSelectedEventIndex] = React.useState(0);

  const [openDialog, setOpenDialog] = React.useState(false);

  const [sendingData, setSendingData] = React.useState(false);
  const [sponsorLoading, setSponsorLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);
  const [participantsToRemove, setParticipantsToRemove] = React.useState<string[]>([]);
  const [selectedParticipantIndex, setSelectedParticipantIndex] = React.useState(0);

  const generatePDF = () => {
    console.log("generating pdf..")
    const pdfContent = <EventSummaryPDF data={eventData[currentIndex]} />

    // Convert the PDF content to a string (XML format)
    const pdfString = pdfContent.toString();

    // Encode the PDF content as bytes (UTF-8)
    const pdfBytes = new TextEncoder().encode(pdfString);

    // Create a Blob from the encoded bytes
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    console.log(pdfBlob)

    // Upload to cloudinary
    uploadToCloudinary(pdfBytes)

  }

  const uploadToCloudinary = async (pdfBlob: any) => {
    // Create a FormData object for uploading the PDF
    handleOpenDataDialog()
    const formData = new FormData();
    //  formData.append('file', pdfBlob);
    formData.append('file', pdfBlob);
    formData.append('upload_preset', "mvfglmtz");

    await axios.post(
      "https://api.cloudinary.com/v1_1/ddcazfrs2/auto/upload",
      formData).then((response) => {
        const data = response.data["secure_url"]
        console.log(data)
        handleEventSummary(data)
      });

  };

  const handleEventSummary = async (pdf_url: any) => {
    const event = eventData[currentIndex]
    for (const emailData of event.waitList) {
      try {
        const response = await fetch('https://fyndr-api-9sff6.ondigitalocean.app/api/v1/event_summary', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "email": emailData.profile.email,
            "first_name": emailData.profile.name,
            "event_data": event,
            "pdf_url": pdf_url,
          }),
        });

        if (response.ok) {
          console.log(`Successfully sent data for ${emailData.profile.email}`);
          setDataLoading(false)
        } else {
          console.error(`Failed to send data for ${emailData.profile.email}: ${response.status} - ${response.statusText}`);
          setDataLoading(false)
        }
      } catch (error) {
        console.error(`Error sending data for ${emailData.profile.email}`);
        setDataLoading(false)
      }

    }

  }

  React.useEffect(() => {
    console.log("event area!")
  }, []);

  const handleParticipantClick = (index: any) => {
    setSelectedEventIndex(index)
    handleOpenDialog(index)
  };

  const handleOpenDialog = (index: any) => {
    setSelectedParticipantIndex(index)
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDataDialog = () => {
    setSendingData(false);
  };

  const handleOpenDataDialog = () => {
    setSendingData(true);
    setDataLoading(true)
  };

  const toggleParticipantToRemove = (email: string) => {
    console.log(email)
    if (participantsToRemove.includes(email)) {
      setParticipantsToRemove(prevSponsors =>
        prevSponsors.filter(existingEmail => existingEmail !== email)

      );
    } else {
      setParticipantsToRemove(prevSponsors => [...prevSponsors, email]);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate();

    const getDaySuffix = (day: number): string => {
      if (day >= 11 && day <= 13) {
        return 'th';
      }
      const lastDigit = day % 10;
      switch (lastDigit) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const daySuffix = getDaySuffix(day);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };

    const formattedDate = new Intl.DateTimeFormat(undefined, options).format(date);

    return formattedDate.replace(day.toString(), day.toString() + daySuffix);
  };

  const statistics = {
    totalMembers: eventData[currentIndex].waitList.length,
    connectionsMade: 0,
    membersActive: eventData[currentIndex].waitList.length,
    connectionsSuggested: 0,
  };

  const handleRemoveParticipant = (participant: any) => {
    // handleCloseDialog()
    console.log(participant)
    removeParticipantsFromEvent(participant)
  }

  const removeParticipantsFromEvent = async (participantsToRemove: any) => {
    console.log(participantsToRemove)
    setSponsorLoading(true)
    const event_id = eventData[currentIndex].eventIdentifier
    try {
      const response = await fetch(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/remove_participant_from_event/${event_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ participantsToRemove }),
      });

      if (response.ok) {
        console.log(response)
        // setSponsorLoading(false)
        handleCloseDialog()
        // retrieveSponsorData(selectedEventIndex);
        getModifiedHostedEvents(myEmail, setEventData, setIsLoading);
      }
    } catch (error) {
      console.error('Error removing sponsors:', error);
      setIsLoading(false)
      handleCloseDialog()
    }
  };

  const shutEvent = async () => {
    setSponsorLoading(true)
    const event_id = eventData[currentIndex].eventIdentifier
    try {
      const response = await fetch(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/shut_event/${event_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify({ participantsToRemove }),
      });

      if (response.ok) {
        console.log(response)
        // setSponsorLoading(false)
        handleCloseDialog()
        // retrieveSponsorData(selectedEventIndex);
        getModifiedHostedEvents(myEmail, setEventData, setIsLoading);
      }
    } catch (error) {
      console.error('Error removing sponsors:', error);
      setIsLoading(false)
      handleCloseDialog()
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <p className='font-sans font-black text-xl'>Confirm action</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p className='font-inter text-sm'>
              {selectedParticipantIndex && selectedParticipantIndex >= 0 ?
                `Remove ${eventData[currentIndex].waitList[selectedParticipantIndex].profile.name} ${eventData[currentIndex].waitList[selectedParticipantIndex].profile.lname} from the list of participants?` :
                'Invalid participant index'
              }
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No, thanks</Button>
          {!sponsorLoading ? (
            <Button onClick={() => handleRemoveParticipant([eventData[currentIndex].waitList[selectedParticipantIndex].profile.email])}>Confirm</Button>
          ) : (
            <CircularProgress className="text-primary text-md" variant="solid" thickness={2} />
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={sendingData}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDataDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <p className='font-sans font-black text-xl'></p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

            {!dataLoading ? (
              <p>Data sent to sponsors!</p>
            ) : (
              <CircularProgress className="text-primary text-md" variant="solid" thickness={2} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            {!dataLoading ? (
               <Button onClick={() => handleCloseDataDialog()}>Dismiss</Button>
            ) : (
              null
            )}
         
        </DialogActions>
      </Dialog>

      {/* Top Element */}
      <div className="p-8 bg-transparent">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-transparent">
          <div className='bg-transparent'>
            <div className='flex flex-row gap-8'>
              <p className="font-inter text-label text-2xl font-bold mb-2 text-left">{eventData[currentIndex].eventName}</p>
              {eventData[currentIndex].eventStatus === "started" && (
                <div className='flex items-center'>
                  <PiDotOutlineFill className='text-green-300' />
                  <p className='text-green-300'>Happening now</p>
                </div>
              )}

              {/* <PDFDownloadLink
        document={<EventSummaryPDF data={eventData[currentIndex]} />}
        fileName="movielist.pdf"
        style={{
          textDecoration: "none",
          padding: "10px",
          color: "#4a4a4a",
          backgroundColor: "#f2f2f2",
          border: "1px solid #4a4a4a"
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : url
        }
      </PDFDownloadLink> */}


              {eventData[currentIndex].eventStatus === "pending" && (
                <div className='flex items-center'>
                  <PiDotOutlineFill className='text-yellow-300' />
                  <p className='text-yellow-300'>Upcoming</p>
                </div>
              )}

              {eventData[currentIndex].eventStatus === "archived" && (
                <div className='flex items-center'>
                  <PiDotOutlineFill className='text-gray-300' />
                  <p className='text-gray-300'>Archived</p>
                </div>
              )}
            </div>
            {/* Meta data about the event */}
            <p className='font-inter text-label text-xs text-left'>{formatDate(eventData[currentIndex].eventDate)}</p>
            <p className='font inter text-label text-xs text-left'>{eventData[currentIndex].eventLocation.address}</p>
          </div>

          {/* <button className="bg-red-500 text-white px-4 py-2 rounded mt-3">
              Shut Down Event
            </button> */}
          <div className='flex flex-row gap-8 bg-transparent w-full justify-start md:justify-end items-center'>

            {eventData[currentIndex].eventStatus === "started" && (
              <IoIosPower onClick={() => shutEvent()} size={30} className='hover:text-red-200 text-green-200' />
            )}

            {eventData[currentIndex].eventStatus === "pending" && (
              <IoIosPower size={30} className=' text-label' />
            )}

            {eventData[currentIndex].eventStatus === "archived" && (
              <IoIosPower size={30} className=' text-label' />
            )}
            {/* <IoIosPower size={30} className='hover:text-primary text-label' /> */}
            {/* <AiOutlineMail onClick={() => generatePDF()} size={30} className='md:text-left hover:text-primary text-label' /> */}
            <BlobProvider document={<EventSummaryPDF data={eventData[currentIndex]} />}>
              {({ blob, url, loading, error }) => {
                if (loading) {
                  console.log('Loading PDF...');
                } else if (blob) {
                  console.log(blob)
                  return (
                    // <a href={url} target="_blank" rel="noopener noreferrer">
                    //   Open PDF
                    // </a>
                    <AiOutlineMail onClick={() => uploadToCloudinary(blob)} size={30} className='md:text-left hover:text-primary text-label' />
                  );
                } else {
                  return 'Error loading PDF.';
                }
              }}
            </BlobProvider>
          </div>

        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">


        {/* First Row, First Column */}
        <div className="p-8 border dark:border-gray-700 border-inherit rounded-lg bg-transparent flex flex-col w-full h-[40vh]">
          <h3 className="text-lg text-label text-left font-black font-sans mb-0">Participants</h3>
          {eventData.length > 0 && eventData[currentIndex].waitList.length > 0 ? (
            <div className="overflow-x-auto bg-transparent w-full flex self-center pl-0 pt-5">
              <table className="w-full bg-transparent">
                <tbody className='h-1/2'>
                  {eventData[currentIndex].waitList.slice(0, 10).map((participant: any, index: any) => (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={() => setSelectedEventIndex(index)}
                    >
                      {/* Avatar */}
                      <td className="px-4 py-4">
                        <Checkbox icon={<MdCheckBoxOutlineBlank className='text-label' />} checkedIcon={<MdCheckBox className='text-label' />} className='text-label' />

                      </td>
                      <td className="px-4 py-4">
                        <div className='flex flex-row text-secondary hover:text-primary'>
                          <Avatar src={participant?.profile.image} className="w-10 h-10 rounded-full">
                            <Skeleton variant="circular" width={10} height={10} />
                          </Avatar>
                          <div className='flex flex-col'>
                            <p className="font-inter ml-2 text-left">{participant?.profile?.name + " " + participant?.profile.lname}</p>
                            <p className="text-label font-inter font-thin text-left ml-2 text-xs">{participant?.profile.offer}</p>
                          </div>
                        </div>


                      </td>
                      {/* Ban Button */}
                      <td className="px-4 py-4">
                        {/* <button className="bg-red-500 text-white px-2 py-1 rounded">
                        Remove
                      </button> */}
                        <IoMdRemoveCircleOutline onClick={() => handleParticipantClick(index)} className='hover:text-primary text-label' />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>

          ) : (
            <div className='flex justify-center items-center h-full bg-transparent'>
              <p className='font-inter text-label text-md'>No participants</p>
            </div>

          )}



        </div>


        {/* First Row, Second Column */}
        <div className="bg-transparent p-0 rounded-lg border dark:border-gray-700 border-inherit h-[40vh]">
          <h3 className="text-lg font-semibold mb-3"></h3>
          {/* Expanded data about the currently selected user */}
          {eventData[currentIndex].waitList.length > 0 ? (
            <div>
              <div className='border-b dark:border-gray-700 border-inherit w-full'>
                <div className="flex items-center p-4">
                  <Avatar src={eventData[currentIndex].waitList[selectedEventIndex].profile.image} className="w-12 h-12 rounded-full mr-3">
                    <Skeleton variant="circular" width={12} height={12} />
                  </Avatar>
                  <div className=''>
                    <p className="font-semibold font-inter text-secondary text-left">{eventData[currentIndex].waitList[selectedEventIndex].profile.name}</p>
                    <p className="text-label font-inter text-sm">{eventData[currentIndex].waitList[selectedEventIndex].profile.offer}</p>
                  </div>
                </div>
              </div>


              <div className="mt-3 p-4">
                <p className="font-semibold font-inter text-secondary text-left">About Person</p>
                <p className="text-label text-left font-inter mt-0">{eventData[currentIndex].waitList[selectedEventIndex].profile.about}</p>
                <p className="font-bold font-inter text-secondary mt-4 text-left">Looking for</p>
                <p className="font-inter text-label text-left mt-0">{eventData[currentIndex].waitList[selectedEventIndex].profile.lookingfor}</p>
              </div>

              {/* <div className='border-t w-full p-4'>
            <div className="flex flex-row gap-2 justify-center align-middle items-center">
              <AiOutlineMail className='text-label' />
              <p className="text-label text-sm text-left font-inter align-middle">{eventData[currentIndex].waitList[selectedEventIndex].profile.email}</p>
            </div>
          </div> */}
            </div>
          ) : (
            <div className='flex justify-center items-center h-[40vh]'>
              <p className='font-inter text-label'>No user selected</p>
            </div>
          )}
        </div>

        {/* Second Row, First Column */}

        <div className="p-8 border dark:border-gray-700 border-inherit rounded-lg bg-transparent flex flex-col w-full max-h-[70vh] min-h-[40vh]">
          <h3 className="text-xl font-semibold mb-4"></h3>
          <div className="card-content h-full">
            {eventData[currentIndex].waitList.length > 0 ? (
              <EventStatisticsChart statistics={statistics} />
            ) : (
              <div className='h-full flex items-center justify-center'>
                <p className='font-inter font-md text-label'>No Chart available</p>
              </div>
            )}
          </div>
        </div>

        {/* Second Row, Second Column */}

        <div className="p-0 bg-transparent rounded-lg border dark:border-gray-700 border-inherit w-full h-full">

          {eventData[currentIndex].chats[0].length > 0 ? (
            <div className='bg-transparent p-8'>
              <h3 className="text-label font-black font-sans mb-0">Active Conversations</h3>
              {eventData[currentIndex].chats[0].map((conversation: any, index: any) => (
                <tr
                  key={index}
                  className="cursor-pointer"
                // onClick={() => handleParticipantClick(index)}
                >
                  {/* Avatar */}
                  {/* <td className="px-4 py-4">
                      <Checkbox/>
                    </td> */}
                  <td className="px-4 py-4 w-1/2">
                    <div className='flex flex-row text-secondary hover:text-primary'>
                      <Avatar src={conversation.user.data} className="w-10 h-10 rounded-full" />
                      <div className='flex flex-col'>
                        <p className="font-inter ml-2 text-left">{conversation.user.name}</p>
                        <p className="text-label font-inter font-thin text-left ml-2 text-xs">{conversation.text}</p>
                      </div>
                    </div>


                  </td>
                  {/* Ban Button */}

                </tr>
              ))}
              <p></p>
            </div>
          ) : (
            <div className='bg-transparent h-full p-8'>
              <h3 className="text-lg text-left font-black text-label font-sans mb-0">Active Conversations</h3>
              <div className='flex justify-center items-center h-full'>

                <p className='font-inter text-label tex-xl'>No conversations yet</p>
              </div>
            </div>

          )}

          {/* Last 4 conversations */}
        </div>
      </div>

    </div>

  );
}

export default EventArea