import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route ,Link, Routes} from "react-router-dom";
import HomeScreen from './components/pages/home';
import Dashboard from './components/pages/dashboard';


function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
