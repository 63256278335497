
// export const getEvents = async (setEventDataCallback: any, setIsLoadingCallback: any) => {
//     setIsLoadingCallback(true);
//       try {
        
//         const response = await fetch('https://dev.fyndr.tech/api/v1/events', {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//         const json = await response.json();
//         const modifiedEvents: any = [];
//         if (json.status === 200) {
  
//           const eventsWithId = json.events.map((event: any, index: any) => ({
//             ...event,
//             id: index + 1,
//           }));
  
//           // console.log("Events with ID", eventsWithId)
  
//           for (const event of eventsWithId) {
//             const modifiedWaitList = await fetchUserDetails(event.waitList);
//             const modifiedEventHost = await fetchUserDetails([event.eventHost]);
//             const modifiedEvent = {
//               ...event,
//               waitList: modifiedWaitList,
//               eventHost: modifiedEventHost[0], // Assuming only one event host
//             };
  
//             modifiedEvents.push(modifiedEvent);
//           }
//           // console.log(modifiedEvents)
//           setEventDataCallback(modifiedEvents);
//           setIsLoadingCallback(false);
//         } else {
//           setIsLoadingCallback(false);
//           // console.log(json.msg);
//         }
//       } catch (error) {
//         console.log(error);
//         setIsLoadingCallback(false);
//       }
//     };
  
  
  // const fetchUserDetails = async (emails: any) => {
  //     const userDetails = [];
  
  //     for (const email of emails) {
  //       try {
  //         const userDetailsResponse = await fetch(`https://dev.fyndr.tech/api/v1/user_with_email?user_email=${email}`, {
  //           method: 'GET',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //         });
  
  //         if (userDetailsResponse.ok) {
  //           const userDetail = await userDetailsResponse.json();
  //           userDetails.push(userDetail);
  //         } else {
  //           console.log(`Failed to fetch user details for email: ${email}`);
  //         }
  //       } catch (error) {
  //         console.log(`Error fetching user details for email: ${email}`, error);
  //       }
  //     }
  
  //     return userDetails;
  //   };
  
  
  //   export const getHostedEvents = async (email: any, setEventCallback: any, setIsLoadingCallback: any) => {
  //     fetch(`https://dev.fyndr.tech/api/v1/events_with_host?eventHost=${email}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         // console.log('Success:', data);
  //         setEventCallback(data.event);
  //         // setIsLoadingCallback(false)
  
  //       }
  //       )
  //       .catch((error) => {
  //         console.error('Error:', error);
  //       }
  //       );
  //   }
  

    // export const getModifiedHostedEvents = async (email: any, setEventDataCallback: any, setIsLoadingCallback: any) => {
    //     setIsLoadingCallback(true);
    //       try {
            
    //         const response = await fetch(`https://dev.fyndr.tech/api/v1/events_with_host?eventHost=${email}`, {
    //           method: 'GET',
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //         });
    //         const json = await response.json();
    //         const modifiedEvents: any = [];
    //         if (json.status === 200) {
      
    //           const eventsWithId = json.events.map((event: any, index: any) => ({
    //             ...event,
    //             id: index + 1,
    //           }));
      
    //           console.log("Events with ID", eventsWithId)
      
    //           for (const event of eventsWithId) {
    //             const modifiedWaitList = await fetchUserDetails(event.waitList);
    //             const modifiedEventHost = await fetchUserDetails([event.eventHost]);
    //             const modifiedEvent = {
    //               ...event,
    //               waitList: modifiedWaitList,
    //               eventHost: modifiedEventHost[0], // Assuming only one event host
    //             };
      
    //             modifiedEvents.push(modifiedEvent);
    //           }
    //           console.log(modifiedEvents)
    //           setEventDataCallback(modifiedEvents);
    //           setIsLoadingCallback(false);
    //         } else {
    //           setIsLoadingCallback(false);
    //           // console.log(json.msg);
    //         }
    //       } catch (error) {
    //         console.log(error);
    //         setIsLoadingCallback(false);
    //       }
    //     };

export const getModifiedHostedEvents = async (email: any, setEventDataCallback: any, setIsLoadingCallback: any) => {
    // console.log(email)
    console.log("setcallbacktrue")
    setIsLoadingCallback(true);
    fetch(`https://dev.fyndr.tech/api/v1/events_with_host?eventHost=${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        const modifiedEvents: any = [];
        // console.log(data)
        // Fetch user details for each email in the waitList
        for (const event of data.event) {
          const modifiedWaitList = await fetchUserDetails2(event.waitList);
          const modifiedEventHost = await fetchUserDetails2([event.eventHost]);
          const modifiedEventChat = await loadChat(event.eventIdentifier);
          // console.log("modifiedEventchat", modifiedEventChat)

          const modifiedEvent = {
            ...event,
            waitList: modifiedWaitList,
            eventHost: modifiedEventHost[0], // Assuming only one event host
            chats: modifiedEventChat
          };

          modifiedEvents.push(modifiedEvent);
        }
        console.log("modified events", modifiedEvents)

        // Use the modified events data
        setEventDataCallback(modifiedEvents);
        setIsLoadingCallback(false);
        console.log("setcallbackfalse")
      }
      )
      .catch((error) => {
        console.error('Error:', error);
      });
};
        
const fetchUserDetails2 = async (emails: any) => {
  const userDetails = [];

  for (const email of emails) {
    try {
      const userDetailsResponse = await fetch(`https://dev.fyndr.tech/api/v1/user_with_email?user_email=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (userDetailsResponse.ok) {
        const userDetail = await userDetailsResponse.json();
        userDetails.push(userDetail);
      } else {
        console.error(`Failed to fetch user details for email: ${email}`);
      }
    } catch (error) {
      console.error(`Error fetching user details for email: ${email}`, error);
    }
  }

  return userDetails;
};

const loadChat = async (eventId: any) => {
  const Chat = [];
  try {
    console.log("loading chat");

    const responseGroupChat = await fetch(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/chats/get_group_chat?event_id=${eventId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const groupChatData = await responseGroupChat.json();
    const chatId = groupChatData.chats[0]._id;

    const responseChatHistory = await fetch(`https://fyndr-api-9sff6.ondigitalocean.app/api/v1/chats/get_chats?chat_id=${chatId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const chatHistoryData = await responseChatHistory.json();
    const chatHistory = chatHistoryData.chats.messages;

    const formattedChatHistory = chatHistory.map((message: any) => ({
      _id: message.text._id,
      text: message.text.text,
      createdAt: new Date(message.createdAt),
      user: {
        _id: message.user._id,
        name: message.user.name,
        data: message.user.data,
      },
    }));

    // console.log("formatted chat history", formattedChatHistory);
    Chat.push(formattedChatHistory)
    // return formattedChatHistory;
  } catch (error) {
    console.error('Error:', error);
    return []; // Return an empty array or handle the error accordingly.
  }
  console.log(Chat)
  return Chat
};


