import React from 'react';

import { Layout } from '../layouts/Layout.js';

import Header from '../layouts/Header';
import Auth from '../layouts/Auth';

const HomeScreen: React.FC = () => {

  const [variant, setVariant] = React.useState("static");

  const variants = ["static", "floating", "sticky"];

  return (
    <Layout>
      <Header/>
      <Auth/>
      
      {/* <VariantsSelectorWrapper>
        <Card css={{maxW: "50%"}}>
          <Card.Body css={{pt: "$8", px: "$8"}}>
            <Radio.Group
              defaultValue="default"
              label="Select variant"
              orientation="horizontal"
              size="sm"
              value={variant}
              onChange={setVariant}
            >
              {variants.map((variant) => (
                <Radio key={variant} value={variant}>
                  {variant}
                </Radio>
              ))}
            </Radio.Group>
          </Card.Body>
        </Card>
      </VariantsSelectorWrapper>       */}
    </Layout>
  );
};

export default HomeScreen;
