import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import jwt_decode from "jwt-decode";

// const authUrl =
// `https://www.linkedin.com/oauth/v2/authorization` +
// `?response_type=code` +
// `&client_id=${linkedinClientId}` +
// `&redirect_uri=${encodeURIComponent(linkedinRedirectUri)}` +
// `&state=${encodeURIComponent(state)}` +
// `&scope=email,openid,profile,r_emailaddress,r_liteprofile`;

// const linkedin_response = await AuthSession.startAsync({ authUrl });
// // console.log(linkedin_response);
// setResult(linkedin_response);
// };


// const [request, response, promptAsync] = Google.useAuthRequest({
// androidClientId: "965927352882-5nqi8mc32no4ath041iicv24icehd3ct.apps.googleusercontent.com",
// iosClientId: "965927352882-o7mp36naglsdos35amkim9quin81i13k.apps.googleusercontent.com",
// expoClientId: '965927352882-bdu0f6v4e51ihkr71o43amfargu689cc.apps.googleusercontent.com',

// }, {
// projectNameForProxy: "@dammyaro/fyndr-mobile"
// }
// );



export const SignIn = async(email: any, password: any, setIsLoadingCallback: any, navigate: any) => {
    setIsLoadingCallback(true);
    
    try {
      const response = await fetch("https://dev.fyndr.tech/api/v1/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      });

      const json = await response.json();
      
      if (response.status === 200) {
        setIsLoadingCallback(false);
        localStorage.setItem('token', json.access_token);
        localStorage.setItem('user_token', '1');
        console.log(json)
        // Use React Router or any other navigation library for navigation
        // Example with React Router:
        // import { useHistory } from 'react-router-dom';
        // const history = useHistory();
        // history.replace("/onboarding/set-pin");
        navigate("/dashboard");
        
      } else {
        setIsLoadingCallback(false);
        alert(json.msg);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingCallback(false);
      alert(error);
    }

}

export const socialAuth = async(email: any, method: any, setGoogleLoadingCallback: any, setLinkedinLoadingCallback: any, navigate: any) => {
    console.log(email)
    console.log(method)
    if (method === "google"){
        setGoogleLoadingCallback(true)
      }
      else{
        setLinkedinLoadingCallback(true)
      }
      try {
        const response = await fetch(`https://dev.fyndr.tech/api/v1/socials/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
          })
        });
        const json = await response.json();
        console.log(json);
        if (response.status === 200) {
          // setIsLoading(false);
          setGoogleLoadingCallback(false)
          setLinkedinLoadingCallback(false)
          localStorage.setItem('token', json.access_token);
          localStorage.setItem('user_token', '1');
          navigate("/dashboard")
        }
        else {
          setGoogleLoadingCallback(false)
          setLinkedinLoadingCallback(false)
          alert(json.msg)
        }
      } catch (error) {
        console.log(error);
        setGoogleLoadingCallback(false)
        setLinkedinLoadingCallback(false)
        alert(error)
      }

}

export const signOut = async (setIsLoadingCallback: any, navigate: any) => {
    // setIsLoadingCallback(true);
    console.log("logging out")
    try {
      localStorage.setItem('token', '');
      localStorage.setItem('user_token', '0');
      setIsLoadingCallback(false);
      navigate("/")
    } catch (error: any) {
      console.log(error);
      // setIsLoadingCallback(false);
      alert(error)
    }
  };

  export const checkToken = async (navigate: any) => {
    try {
      const token = localStorage.getItem('token')
      const user_token = localStorage.getItem('user_token');

      if (user_token === '1' && token) {
        const decodedToken: any = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        
        if (decodedToken.exp > currentTime) {
          navigate("/dashboard")
          
        } else {
          localStorage.removeItem('token');
          navigate("/")
        }
      } else {
        navigate("/")
      }
    } catch (error) {
      console.log(error);
    }
  };